import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { trackClick } from './util/trackClick';
import { getReferrer } from './util/api/index';

const Invite = () => {

  const { code } = useParams();
  const [displayName, setdisplayName] = useState();
  trackClick(code);

  useEffect(() => {
    const url = `https://chippercash.app.link/referrals?invite_code=${code}&feature=referrals`;
    setTimeout(() => { window.location = url; }, 4000);

    async function setReferrer(InviteCode) {
      try {
        const user = await getReferrer(InviteCode);
        setdisplayName(user.display_name);
      }
      catch (error) {
        console.log(error);
        return null;
      }
    }
    setReferrer(code);
  });

  return (
    <div className="posCenter" style={{ textAlign: 'center' }}>
      <h2>Congratulations!</h2>
      <h4>Welcome to the Future of African Payments.</h4>
      <br />

      {displayName && (
        <div>
          <h4 style={{ fontWeight: 'bold' }}>{displayName}</h4>
          {displayName && (
            <h4>{'sent you an exclusive invite to Chipper Cash™'}</h4>
          )}
        </div>
      )}
    </div>
  );
};

export default Invite;
