import axios from 'axios';
import getReferrer from './referrals';

const environments = {
  'production': {
    coreUrl: 'https://api.chippercash.com/v1/'
  },
  'staging': {
    coreUrl: 'https://staging.chippercash.com/v1/'
  },
};

const environment = environments[process.env.REACT_APP_ENV] || environments.staging;

axios.defaults.baseURL = environment.coreUrl;

export {
  getReferrer
};
