import axios from 'axios';

async function getReferrer(code) {
  try {
    const response = await axios.get(`/referrals/referrer/${code}`);
    return response.data;
  }
  catch (error) {
    console.log(error);
  }
}


export default getReferrer;
