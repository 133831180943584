import axios from 'axios';

export const trackClick = async (id) => {
  const navigator = window.navigator;
  var _navigator = {};
  for (var i in navigator) {
    _navigator[i] = navigator[i];

    if (i === 'connection') {
      let _connection = {};
      const connection = _navigator[i];
      for (var j in connection) {
        _connection[j] = connection[j];
      }
      _navigator[i] = _connection;
    }
  }

  delete _navigator.plugins;
  delete _navigator.mimeTypes;
  const parsed = JSON.stringify(_navigator);

  // Referrer
  const referrer = document.referrer;

  // Canonical URL
  const canonical_url = window.location.href;

  const data = {
    id,
    navigator: parsed,
    referrer,
    canonical_url
  };

  const url = 'https://boulevard-podcast-analytics.herokuapp.com';

  const instance = axios.create({
    baseURL: url,
    timeout: 5000
  });

  const response = await instance.post('/initial', data);
  return response.data;
};
